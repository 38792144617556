/* Add you fonts below, example */

//@import "~@fontsource/sen/400.css"; /* regular */
//@import "~@fontsource/sen/700.css"; /* medium */
//@import "~@fontsource/sen/800.css"; /* bold */
//
//$font-family-sans-serif: "Sen", "Helvetica Neue", "Arial", "Noto Sans", "Liberation Sans", sans-serif;
//$font-weight-light:           400 !default;
//$font-weight-normal:          700 !default;
//$font-weight-bold:            800 !default;

$primary: #041976;
$secondary: #90cdf4;

$nav-link-font-size: 0.9rem;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

//$enable-caret:                true !default;
$enable-rounded:              false;
//$enable-shadows:              false !default;
$enable-gradients:            true;
//$enable-transitions:          true !default;
//$enable-reduced-motion:       true !default;
//$enable-smooth-scroll:        true !default;
//$enable-grid-classes:         true !default;
//$enable-button-pointers:      true !default;
//$enable-rfs:                  true !default;
//$enable-validation-icons:     true !default;
//$enable-negative-margins:     false !default;
//$enable-deprecation-messages: true !default;
//$enable-important-utilities:  true !default;
