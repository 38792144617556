//.transition_200 {
//  transition: all 0.2s ease-in-out;
//}
//
//.transition_300 {
//  transition: all 0.3s ease-in-out;
//}

.grad_red2orange:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  color: white;
  background-image: linear-gradient(-45deg, #fb0054 0%, #f55b2a 100%);
}

.grad_blue2sky:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  color: white;
  background-image: linear-gradient(-45deg, #3615e7 0%, #44a2f6 100%);
}

.grad_gray2sky:hover {
  box-shadow: 0 0 25px 0 rgba(20, 27, 201, 0.05);
  cursor: pointer;
  background-image: linear-gradient(-45deg, #d5d5d5 0%, #44a2f6 100%);
}
