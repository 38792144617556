.card_landing1 {
  @include media-breakpoint-up(sm) {
    padding-left: 4rem;
    margin: 4rem -4rem 4rem 0;
  }
}

.card_imgdiv1 {
  @include media-breakpoint-up(sm) {
    margin-top: -2rem;
    margin-left: -4rem;
    z-index: 1;
  }
}

.card_landing2 {
  @include media-breakpoint-up(sm) {
    padding-left: 4rem;
    margin: 4rem -4rem 4rem 0;
  }
}

.card_imgdiv2 {
  @include media-breakpoint-up(sm) {
    margin-top: -2rem;
    margin-left: -4rem;
    margin-bottom: 4rem;
    z-index: 1;
  }
}

.card_body1 {
  background-color: #e9f0ff;
  z-index: 0;

  @include media-breakpoint-up(sm) {
    margin-left: -4rem;
    padding: 2rem 2rem 2rem 5rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem;
  }
}

.card_body2 {
  background-color: #e9f0ff;
  z-index: 0;

  @include media-breakpoint-up(sm) {
    margin-left: -4rem;
    padding: 2rem 5rem 2rem 2rem;
  }

  @include media-breakpoint-down(sm) {
    padding: 2rem;
  }
}

.icon-with-caption {
  padding: 40px;
}

.icon-with-caption-image {
  width: 200px;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.icon-with-caption-image img {
  width: 120px;
}

.icon-with-caption-caption {
  display: flex;
  justify-content: center;
  font-weight: bold;
}

.block-image {
  display: inline-block;
}
